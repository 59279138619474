/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React,{useState} from "react";

// Chakra imports
import {
  Box,
  Button,
  Flex,
  Grid,
  Link,
  Text,
  useColorModeValue,
  SimpleGrid,
  Input,
  Icon,
} from "@chakra-ui/react";
import moment from "moment";
// Custom components
import Banner from "views/admin/payment/components/Banner";
import TableTopCreators from "views/admin/payment/components/TableTopCreators";
import HistoryItem from "views/admin/payment/components/HistoryItem";
import NFT from "components/card/NFT";
import Card from "components/card/Card.js";

// Assets
import Nft1 from "assets/img/nfts/Nft1.png";
import Nft2 from "assets/img/nfts/Nft2.png";
import Nft3 from "assets/img/nfts/Nft3.png";
import Nft4 from "assets/img/nfts/Nft4.png";
import Nft5 from "assets/img/nfts/Nft5.png";
import Nft6 from "assets/img/nfts/Nft6.png";
import Avatar1 from "assets/img/avatars/avatar1.png";
import Avatar2 from "assets/img/avatars/avatar2.png";
import Avatar3 from "assets/img/avatars/avatar3.png";
import Avatar4 from "assets/img/avatars/avatar4.png";
import tableDataTopCreators from "views/admin/payment/variables/tableDataTopCreators.json";
import { tableColumnsTopCreators } from "views/admin/payment/variables/tableColumnsTopCreators";
import {message} from 'antd'
import { saveRecords,fetchRecords } from "appRedux/actions";
import {useSelector,useDispatch} from 'react-redux'
import {GET_ALL_PAYMENT, GET_ALL_USERS} from '../../../constants/ServerUrl'
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  
  MdSearch,
} from "react-icons/md";
export default function Marketplace() {
  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const [payment, setPayment] = React.useState([]);
  const {token} = useSelector((state)=>state.UserDetails.userdetails)
  const [startdate,isStartDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
  const [enddate,isEndDate] = useState(new Date());
  const [loading,isLoading] = useState(false)
  React.useEffect(()=>{
    
    let startDate = moment(startdate).format('YYYY-MM-DD')
        let endDate = moment(enddate).format('YYYY-MM-DD')
    getAllPayment(startDate,endDate);
    
    // getDashboardCore()
  },[])
      const getAllPayment = (startdate=null,enddate=null)=>{
        
        fetchRecords(GET_ALL_PAYMENT+`?startdate=${startdate}&enddate=${enddate}`,token).then((res) => {
         
         isLoading(false)
          message.info('All Payment Retrieved')
          setPayment(res.data.allpayment)
          
       }).catch(err => {
          message.error('User Retrieve Failed')
           console.log("error",err?.response?.data);
       });
      }

      const handleDateChange = (date)=>{
        console.log(date)
       isStartDate(date)
      }
      const handleEndDateChange = (date)=>{
        console.log(date)
       isEndDate(date)
      }
      const handleFilterDashboard = ()=>{
        
        let startDate = moment(startdate).format('YYYY-MM-DD')
        let endDate = moment(enddate).format('YYYY-MM-DD')
        getAllPayment(startDate,endDate)
        isLoading(true)
      }
  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      {/* Main Fields */}
      <Grid
        mb='20px'
        gridTemplateColumns={{ xl: "repeat(1, 1fr)", "2xl": "1fr 0.46fr" }}
        gap={{ base: "20px", xl: "20px" }}
        display={{ base: "block", xl: "grid" }}>

          
      
        <Flex
          flexDirection='column'
          gridArea={{ xl: "1", "2xl": "1 / 2 / 2 / 3" }}>


        <Flex mb="3" gap={5} justifyContent="flex-end">
             
             <ReactDatePicker
               selected={startdate}
               onChange={handleDateChange}
               dateFormat="MM/dd/yyyy"
               customInput={
                 <Input
                   type="text"
                   placeholder="Select a date"
                   _placeholder={{ color: "gray.400" }}
                   _focus={{
                     borderColor: "brand.500",
                     boxShadow: "0 0 0 1px brand.500",
                   }}
                 />
             }
             calendarClassName="chakra-date-picker"
              />
               <ReactDatePicker
                   selected={enddate}
                   onChange={handleEndDateChange}
                   dateFormat="MM/dd/yyyy"
                   customInput={
                     <Input
                       type="text"
                       placeholder="Select a date"
                       _placeholder={{ color: "gray.400" }}
                       _focus={{
                         borderColor: "brand.500",
                         boxShadow: "0 0 0 1px brand.500",
                       }}
                     />
                   }
                 calendarClassName="chakra-date-picker"
               />
           <Button isLoading={loading}  onClick={()=>handleFilterDashboard()} colorScheme="blue"><Icon as={MdSearch}/></Button>
   
        </Flex>
          <Card px='0px' mb='20px'>
            <TableTopCreators
              tableData={payment}
              columnsData={tableColumnsTopCreators}
              refresh={getAllPayment}
            />
          </Card>
          {/* <Card p='0px'>
            <Flex
              align={{ sm: "flex-start", lg: "center" }}
              justify='space-between'
              w='100%'
              px='22px'
              py='18px'>
              <Text color={textColor} fontSize='xl' fontWeight='600'>
                Active Users
              </Text>
              <Button variant='action'>See all</Button>
            </Flex>
            <HistoryItem
              name='Temiloluwa Odewumi'
              author='By Mark Benjamin'
              date=''
              image={Nft5}
              price='N20,000'
            />
            <HistoryItem
              name='Geoffrey Odewumi'
              author='By Esthera Jackson'
              date=''
              image={Nft1}
              price='N29,000'
            />
            
          </Card> */}
        </Flex>
      
        
      </Grid>
      {/* Delete Product */}
    </Box>
  );
}
