// Chakra imports
import { SimpleGrid, Text, useColorModeValue } from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import React from "react";
import Information from "views/admin/profile/components/Information";
import moment from "moment";
// Assets
export default function GeneralInformation(props) {
  const { ...rest } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const RetrivedDataComponent = ()=>{
    if(rest.data && rest.data.length > 0){
      return rest.data.map((d,index)=>{
        return <SimpleGrid columns='2' gap='20px'>
        <Information
          boxShadow={cardShadow}
          title='First Name'
          value={d.firstname}
        />
        <Information
          boxShadow={cardShadow}
          title='Last Name'
          value={d.lastname}
        />
        <Information
          boxShadow={cardShadow}
          title='Gender'
          value={d.gender}
        />
        <Information
          boxShadow={cardShadow}
          title='Height'
          value={`${d.height}cm`}
        />
        <Information
          boxShadow={cardShadow}
          title='Weight'
          value={`${d.weight}kg`}
        />
        <Information
          boxShadow={cardShadow}
          title='Date Created'
          value={moment(d.created_at).format('DD-MM-YYYY')}
        />
         <Information
          boxShadow={cardShadow}
          title='Email'
          value={d.email}       />
         <Information
          boxShadow={cardShadow}
          title='Age'
          value={d.age}
        />
      </SimpleGrid>
      })
    }
    else{
      return   <Information
      boxShadow={cardShadow}
      title='No DATA'
      value='No Payment History Found'
    />
    }
  }
  return (
    <Card mb={{ base: "0px", "2xl": "20px" }} {...rest}>
      <Text
        color={textColorPrimary}
        fontWeight='bold'
        fontSize='2xl'
        mt='10px'
        mb='4px'>
        Payment Information
      </Text>
      {/* <Text color={textColorSecondary} fontSize='md' me='26px' mb='40px'>
        As we live, our hearts turn colder. Cause pain is what we go through as
        we become older. We get insulted by others, lose trust for those others.
        We get back stabbed by friends. It becomes harder for us to give others
        a hand. We get our heart broken by people we love, even that we give
        them all...
      </Text> */}
       {rest.loading ? <Information
          boxShadow={cardShadow}
          title='Retrieving Data'
          // value='Please wait'
        />:RetrivedDataComponent()}
      
    </Card>
  );
}
